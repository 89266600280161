import {menuToggle, slider} from './base/base';
import $ from 'jquery';
import 'slick-carousel';

menuToggle();
slider();

$('#homeSlider').slick({
  autoplay: true,
  autuplaySpeed: 5000,
  speed: 1000,
  arrows: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  fade: true
});
